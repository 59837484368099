<script>
	import {
		ref
	} from 'vue'
	import localService from "@/service"
	import VueEvent from '../service/events'
	export default {
		name: 'HotTemplates',
		props: {
			pageShow: {
				type: Boolean,
				default: true
			},
			pageRows: {
				type: Number,
				default: 15
			},
			dataUrl: {
				type: String,
				default: "/ds/iso.public.list_templates_hot"///ds/iso.public.list_favs
			},
			fromPage: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				templates: [],
				page: 1,
				loading: true,
				pageCount: 1,
				nowItem: [],
				nowIndex: 0
			}
		},
		mounted() {
			//this.loadTemplates();
			VueEvent.on("filterTemplates", (args) => {
				this.loadTemplates(args)
			})
			VueEvent.on("execCollect", () => {
				this.collect(this.nowItem, this.nowIndex);
			})
		},
		methods: {
			getIndustry(str) {
				let items = JSON.parse(str)
				return items.length > 0 ? items[0].name : "未指定"
			},

			getFlags(types) {
				if(types==null || types.length==0)
					return [];
				var items=types.split(",")
				if(items.length==0)
					return [];
				var sb=[]
				for(var i=items.length-1;i>=0;i--){
					var item=items[i]
					if(item.indexOf("ISO")==0){
						item=item.substring(3)
						sb.push("../assets/flags/"+item+".png")
					}
				}
				return sb
			},

			loadTemplates(args) {
				let filterArgs = {
					page_rows: this.pageRows,
					page: this.page
				}
				
				if(args){
					this.curFilterArgs=args
					filterArgs.args=localService.utils.encodeCode(JSON.stringify(args))
				}


				localService.http.request({
					url: this.dataUrl,
					params: filterArgs
				}).then(res => {
					
					this.templates = res.data.list;
					this.pageCount = res.data.pageCount;
					this.loading=false
					let _page = Number(window.sessionStorage.getItem("page"));
					if(_page){
						window.sessionStorage.removeItem("page");
						if(_page <= this.pageCount){
							this.page = _page;
						}else{
							_page = this.pageCount;
						}
					}
					let _this = this
					if(this.templates){
						this.templates.forEach(function(item){
							if(!item.favs_added){
								item.favs_added = 0;
								if(_this.fromPage == "favs"){
									item.favs_added = 1;
								}
							}
						})
					}
				});
			},
			itemClick(item) {
				let data = localService.utils.encodeCode(JSON.stringify({
					id: item.id
				}))
				this.$router.push({
					path: "/detail",
					query: {
						_id: data
					}
				})
			},
			onPage() {
				this.loadTemplates(this.curFilterArgs);
				VueEvent.emit("scrollToTop");
			},
			repStar(str){
				return str[0]+"**"+str[str.length-1];
			},
			collect(item,index) {
				if (localService.utils.isUserLogin()) {
					localService.http.request({
						url: "/ds/iso.public.add_favs",
						params: {
							template_id: item.id
						}
					}).then(res => {
						if(res.data.success){
							this.templates[index].favs_added = 1;
							VueEvent.emit("execMessage","收藏成功");
							this.$forceUpdate();
						}
					});
				} else {
					this.nowItem = item;
					this.nowIndex = index;
					VueEvent.emit("execLogin","collect");
				}
			},
			remove(item, index){
				if (localService.utils.isUserLogin()) {
					localService.http.request({
						url: "/ds/iso.public.remove_favs",
						params: {
							template_id: item.id
						}
					}).then(res => {
						if(res.data.success){
							VueEvent.emit("execMessage","已取消收藏");
							window.sessionStorage.setItem("page",this.page);
							this.loadTemplates();
						}
					});
				} else {
					VueEvent.emit("execLogin");
				}
			}
		}
	}
</script>

<template>
	<v-app>
		<div class="loading" v-if="loading"><div style="width:30%;height:30%;position: absolute;left:35%;top:35%;text-align: center;"><center><img src="../assets/loading.gif" /><br/>载入中....</center></div></div>
		<div v-else>
			<div class="content" v-if="templates.length>0">
				<div v-for="(item, index) in templates" class="template_item">
					<div class="cont" @click="itemClick(item)">
						<div class="thumb_area">
							<img :src="item.cover_path" class="thumb" />
							<!--
							<div class="industry">
								<p>{{getIndustry(item.industry)}}</p>
							</div>
							-->
						</div>
						<div class="desc">{{item.template_name}}</div>
						<div class="price">
							<div class="price1">￥{{item.discount_price}}</div>
							
							<div class="price2">原价: ￥{{item.price}}</div>
						
						</div>
						<!--
						<div class="data">
							<div><v-icon>mdi-cart-check</v-icon> {{item.downloads}}次</div>
							<div><v-icon>mdi-star-outline</v-icon> {{item.favorites}}次</div>
						</div>
						-->
						<!--
						<div class="time">
							<v-icon>mdi-account-circle-outline</v-icon>
							{{repStar(item.provider)}} 发布于 {{item.create_time}} 
						</div>
						-->
						<div class="series">
							<!--
							<span><img style="width:60px;height:60px;" src="../assets/flags/9001.png"/></span>
							-->
							<span>
								<img v-for="(item, index) in getFlags(item.types)" :src="item"/>
							</span>
							<!--
							<span v-if="item.cert_type_name">{{item.cert_type_name}}</span>
							<span v-else>未指定</span>
							-->
						</div>
					</div>
					<template v-if="fromPage == 'favs'">
						<div class="collect" @click="remove(item,index)" v-if="item.favs_added==1">
							<v-icon>mdi-star</v-icon><font>取消收藏</font>
						</div>
					</template>
					<template v-else>
						<div class="collect on" @click="remove(item,index)" v-if="item.favs_added==1"><v-icon>mdi-star</v-icon><font>已收藏</font></div>
						<div class="collect" @click="collect(item,index)" v-else><v-icon>mdi-star-outline</v-icon><font>收藏</font></div>
					</template>
				</div>
			</div>
			<div class="nodata" v-else>
				<img src="../assets/nodata.png" />
				<p>暂无数据</p>
			</div>
		</div>
		<div class="pagger" v-if="pageShow&&pageCount>0&&this.templates">
			<v-pagination v-model="page" :length="pageCount" color="#1b66e6" @input="onPage"></v-pagination>
		</div>
	</v-app>
</template>
<style scoped>
	.content {
		width: 100%;
		background-color: #fff;
		display: flex;
		flex-wrap: wrap;
		padding-left: 0.8%;
		padding-top: 1%;
	}

	.nodata{
		min-height: 60vh;
	}
	.loading{
		min-height: 60vh;
		position: relative;
	}

	.template_item {
		width: 19%;
		margin-right: 1%;
		margin-bottom: 1%;
		padding-bottom: 5px;
		cursor: pointer;
		border: 1px solid #fff;
		box-sizing: border-box;
		border-radius: 1px;
		overflow: hidden;
		position: relative;
	}

	.template_item .cont {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.template_item:hover {
		border-color: #185bcd;
		box-shadow: 0 5px 2px rgba(0, 0, 0, .1);
	}

	.template_item .thumb_area {
		flex-direction: column;
		align-items: center;
		background-color: #f7f7f8;
		padding: 15px;
		border: solid 1px #f1f1f1;
		display: flex;
		width: 100%;
		border-radius: 5px;
	}

	.template_item .thumb {
		width: 100%;
		border-radius: 0 0 5px 5px;
	}

	.template_item .industry {
		font-size: 14px;
		background-color: #1b66e6;
		line-height: 32px;
		margin-top: -32px;
		width: 100%;
		color: #fff;
		border-radius: 0px 0px 5px 5px;
	}

	.template_item .industry p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		width: 90%;
		margin: 0 auto;
	}

	.template_item .desc {
		width: 90%;
		font-size: 15px;
		font-weight: bold;
		margin-top: 15px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		line-height: 22px;
		text-align: justify;
		word-break: break-all;
	}

	.template_item .price {
		display: flex;
		width: 90%;
		margin: 5px 0;
	}

	.template_item .price .price1 {
		width: 50%;
		font-size: 18px;
		line-height: 24px;
		font-weight: bold;
		color: #f00;
	}

	.template_item .price .price2 {
		width: 50%;
		text-align: right;
		font-size: 14px;
		line-height: 24px;
		text-decoration: line-through;
		color: gray;
	}

	.template_item .data {
		display: flex;
		width: 90%;
		justify-content: space-between;
		flex-direction: row;
		font-size: 15px;
		color: #333;
	}

	.template_item .data i {
		color: #185bcd;
		font-size: 20px;
		vertical-align: middle;
	}

	.template_item .data i.mdi-star-outline {
		font-size: 24px;
		position: relative;
		transform: translateY(-2px);
	}

	.template_item .time {
		display: flex;
		width: 90%;
		text-align: left;
		font-size: 13px;
		line-height: 20px;
		color: #666;
		position: relative;
		padding-left: 25px;
		margin-top: 10px;
	}

	.template_item .time i {
		color: #185bcd;
		font-size: 20px;
		margin-right: 5px;
		position: absolute;
		left: 0;
	}

	.template_item .series {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 13px;
		line-height: 26px;
		width: 80%;
	}

	.template_item .series::before {
		content: "";
		display: block;
		width: 100%;
		height: 26px;
		position: absolute;
		right: 5px;
		border-radius: 3px;
		transform: skewX(-20deg);
	}

	.template_item .series span {
		display: block;
		position: relative;
		color: #fff;
		padding-right: 20px;
		padding-left: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.template_item .series span img{
		width: 50px;
		height: 50px;
		margin-right: 5px;
		float: right;
	}

	.template_item .collect {
		position: absolute;
		background: #17a0ff;
		left: 0;
		top: -30px;
		border-radius: 3px;
		line-height: 24px;
		height: 26px;
		transition: all .2s;
		color: #fff;
		font-size: 12px;
		padding: 0 5px;
	}
	.template_item .collect.on{
		top: 0;
	}

	.template_item .collect i {
		color: #fff;
		font-size: 17px;
		position: relative;
		transform: translateY(-1px);
	}

	.template_item .collect font {
		margin: 0 3px;
		/* display: none; */
	}

	.template_item .collect:hover font {
		display: inline-block;
	}

	.template_item:hover .collect {
		top: 0;
	}
	.pagger {
		margin: 20px auto 50px auto;
	}
</style>