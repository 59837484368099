//引入封装好的 service
import localService from "@/service"
import axios from "./axiosInstance"
import store from "../store"

//声明一个基础接口变量
let baseURL;

/*
//配置开发环境
if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://127.0.0.1';
}
 
// 配置生产环境
if (process.env.NODE_ENV === 'production') {
  baseURL = 'http://iso.easy9001.cn';
}
*/

//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
const header = {
	Accept: 'application/json;charset=UTF-8',
}

//根据自身需求
let _httpService = {
	baseURL: baseURL,
	request(data) {
		//const url = baseURL + data.url;
		const url = data.url;
		//console.log("url=="+url)
		return axios({
			headers: {
				'Content-type': 'application/x-www-form-urlencoded'
			},
			method: "post",
			url: url,
			params: data.params
		})
	}
}

let utils = {
	fmoney: (s, n) => {
		var v = parseFloat(s);
		var prefix = v >= 0 ? "" : "-";
		s = Math.abs(v);
		n = n > 0 && n <= 20 ? n : 2;
		s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
		var l = s.split(".")[0].split("").reverse(),
			r = s.split(".")[1];
		let t = "";
		for (var i = 0; i < l.length; i++) {
			t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
		}
		return prefix + t.split("").reverse().join("") + "." + r;
	},
	encodeCode: (strData) => {
		return encodeURIComponent(BASE64.encode(strData))
	},
	decodeCode: (strEncoded) => {
		return BASE64.decode(decodeURIComponent(strEncoded))
	},
	getUserInfo: () => {
		let strUserInfo = sessionStorage.getItem("userInfo")
		try {
			if (strUserInfo != null && strUserInfo.length > 0)
				return JSON.parse(strUserInfo)
		} catch (ex) {
			return null;
		}
	},
	setSessionItem: (key, value) => {
		if (value) {
			sessionStorage.setItem(key, JSON.stringify(value))
		} else {
			sessionStorage.removeItem(key)

		}
	},
	getSessionItem: (key) => {
		let strUserInfo = sessionStorage.getItem(key)
		try {
			if (strUserInfo != null && strUserInfo.length > 0)
				return JSON.parse(strUserInfo)
		} catch (ex) {
			return null;
		}
	},
	setUserInfo: (userInfo) => {
		//console.info("setUserInfo====>>"+JSON.stringify(userInfo))
		if (userInfo) {
			sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
			// console.log(sessionStorage)
		} else{
			sessionStorage.removeItem("userInfo");
			// console.log(sessionStorage)
		}
	},
	isUserLogin: () => {
		let userInfo = utils.getUserInfo()
		//console.log("isUserLogin===="+userInfo+"==="+JSON.stringify(userInfo))
		return userInfo != null && userInfo.id != null
	},
	submit(form) {
		var raw = [];
		//raw.push({name:"docid",value:ret.doc_id});
		raw.push({
			name: "project_name",
			value: form.project_name
		});
		raw.push({
			name: "config_options",
			value: {
				type: "product",
				template_id: store.state.currentTemplate.id,
				config_id: store.state.currentTemplate.cert_type
			}
		});
		var industry = JSON.parse(store.state.currentTemplate.industry);
		var strIndustry = industry.length > 0 ? industry[0].path : "未定义";
		raw.push({
			name: "industry",
			value: strIndustry
		});
		raw.push({
			name: "classify",
			value: "cert_projects"
		});
		//alert("rw=="+JSON.stringify(raw))
		localService.http.request({
			url: "/ds/dgcloud.cms.update_content",
			params: {
				from: 1,
				table: "cert_projects",
				raw: JSON.stringify(raw)
			}
		}).then(res => {
			if(res.data.success){
				// return res.data.bean.docid;
				return "ffffff"
			}
		})
	},
	getDate(date){
		var year = date.getFullYear();
		var month = date.getMonth() + 1;
		var day = date.getDate();
		if (month < 10) {
		  month = "0" + month;
		}
		if (day < 10) {
		  day = "0" + day;
		}
		return year + "." + month + "." + day;
	},
}

let constants = {
	banks: [{
			name: "中国工商银行",
			thumb: "/mobile/assets/img/banks/zggs.png"
		},
		{
			name: "中国农业银行",
			thumb: "/mobile/assets/img/banks/zgny.png"
		},
		{
			name: "中国银行",
			thumb: "/mobile/assets/img/banks/zgyh.png"
		},
		{
			name: "中国建设银行",
			thumb: "/mobile/assets/img/banks/zgjs.png"
		},
		{
			name: "交通银行",
			thumb: "/mobile/assets/img/banks/jtyh.png"
		},
		{
			name: "中国邮政银行",
			thumb: "/mobile/assets/img/banks/zgyz.png"
		},
		{
			name: "中信银行",
			thumb: "/mobile/assets/img/banks/zxyh.png"
		},
		{
			name: "中国光大银行",
			thumb: "/mobile/assets/img/banks/gdyh.png"
		},
		{
			name: "华夏银行",
			thumb: "/mobile/assets/img/banks/hxyh.png"
		},
		{
			name: "广发银行",
			thumb: "/mobile/assets/img/banks/gfyh.png"
		},
		{
			name: "招商银行",
			thumb: "/mobile/assets/img/banks/zsyh.png"
		},
		{
			name: "兴业银行",
			thumb: "/mobile/assets/img/banks/xyyh.png"
		},
		{
			name: "上海浦东发展银行",
			thumb: "/mobile/assets/img/banks/shpf.png"
		},
		{
			name: "中国民生银行",
			thumb: "/mobile/assets/img/banks/zgms.png"
		},
		{
			name: "平安银行",
			thumb: "/mobile/assets/img/banks/payh.png"
		},
	]
}


//导出
export default {
	http: _httpService,
	constants: constants,
	utils: utils,
}