<script>
	import MainHeader from '../components/MainHeader.vue'
	import HotTemplates from '@/components/HotTemplates.vue';
	import MainFooter from '../components/MainFooter.vue';
	import UserLogin from '@/components/UserLogin.vue';
	export default {
		name: 'MainView',
		components: {
			MainHeader,
			HotTemplates,
			MainFooter,
			UserLogin
		},
		data: () => ({
			showBanner: false,
		}),
		created() {
			 document.addEventListener('scroll', this.handleScroll, true);
		},
		methods: {
			handleScroll(){
				this.$nextTick(()=>{
					var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
					if(top >= 220){
						this.showBanner = true;
					}else{
						this.showBanner = false;
					}
				})
			},
			toList() {
				this.$router.push({
					path: "/list",
				})
			}
		}
	};
</script>

<template>
	<v-app>
		<!--
		<div :class='showBanner?"banner on":"banner"'>
			<MainHeader theme="light" showSearch="false" />
		</div>
		-->
		
		<MainHeader theme=""  showSearch="true" showMenu="true" menuId="0" />
		
		<div class="hot">
			<div class="path">
				<font>首页</font><v-icon>mdi-chevron-right</v-icon>
				<span>模版推荐</span>
			</div>
			<div class="hotWrap">
				<HotTemplates />
			</div>
		</div>
		<MainFooter />
		<UserLogin />
	</v-app>
</template>

<style scoped>


	.path{
		width: 85vw;
	}
	.hot {
		width: 100vw;
		background: #f6f6f6;
		padding-top: 70px;
		background: #f6f6f6;
	}

	.caption {
		width: 85vw;
		min-width: 1200px;
		margin: 0 auto;
		font-size: 18px !important;
		font-weight: bold;
		color: #333;
		padding: 20px 0;
		line-height: 30px;
	}


	.caption label {
		font-size: 14px;
		color: #333;
		margin-left: 30px;
	}
	

	.caption a {
		float: right;
		font-size: 16px;
		color: #666;
		cursor: pointer;
	}

	.caption a:hover {
		color: #333;
	}

	.caption a i {
		vertical-align: sub;
		color: #999;
	}

	.caption a:hover i {
		color: #666;
	}

	.hotWrap {
		width: 85vw;
		min-width: 1200px;
		margin: 0 auto 20px auto;
	}
</style>