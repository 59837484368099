//axiosInstance.js
//导入axios
import axios from 'axios'
//axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。


// Add a request interceptor
axios.interceptors.request.use(function (config) {
	//console.log("request===="+config)
	// Do something before request is sent
	return config;
	}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
	//console.log("response=="+response)
	// Do something with response data
	return response;
	}, function (error) {
	// Do something with response error
	return Promise.reject(error);
});


//导出我们建立的axios实例模块，ES6 export用法
export default axios
