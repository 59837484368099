import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userInfo: {},
		currentProject: {},
		currentTemplate: {}
	},
	getters: {},
	mutations: {
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo;
		},
		setCurrentProject(state, project) {
			state.currentProject = project;
		},
		setCurrentTemplate(state, template) {
			state.currentTemplate = template;
		}
	},
	actions: {},
	modules: {}
})