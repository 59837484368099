<script>
	import {
		h,
		ref
	} from 'vue'
	import localService from "@/service"
	import store from "@/store"
	import VueEvent from '../service/events'
	export default {
		name: 'MainHeader',
		props: {
			theme: "",
			showMenu: false,
			showSearch: true,
			menuId: 0,
			txtKey: {
				type: String,
				default: ""
			},
			type: {
				type: String,
				default: ""
			}
		},

		data() {
			return {
				//series: ["ISO9001","ISO14001","ISO45001","ISO50430","ISO50001"],
				category: "ISO9001",
				certTypes: [{name:"质量管理体系",value:"ISO9001"},{name:"环境管理体系",value:"ISO14001"},{name:"职业健康安全管理体系",value:"ISO45001"},{name:"工程施工质量管理体系",value:"ISO50430"},{name:"能源管理体系",value:"ISO50001"}],
				selectedType:{name:"质量管理体系",value:"ISO9001"},
				left: 50,
				width: 60,
				searchPlaceHolder:"请输入关键词搜索...",
				keywords: ["五金","纺织","三体系","销售","质量认证","机械加工"],
				hyfl: [],
				selectedHyItem: {},
				clientX: '',
				clientY: '',
				fav: true,
				menu: false,
				kwords: "",
				type_filter: "",
				showCategory: false,
				showCatList: true,
				cateText: "所有行业",
				cateId1: 0,
				cateId2: 0,
				message: false,
				hints: true,
				userInfo: {
					nickname: "johon1",
					corp:"",
					headImgUrl: "/shared/images/demo/john.jpg"
				},
				hasLogin: localService.utils.isUserLogin()
			}
		},
		watch: {
			txtKey(newValue, oldValue) {
				this.kwords = newValue
			},
			type(newValue, oldValue) {
				this.type_filter = newValue
				console.log("new"+newValue)
			}
		},
		mounted() {
			var _this = this;
			_this.loadHyfl();
			if (_this.hasLogin) {
				_this.userInfo = localService.utils.getUserInfo()
				// this.userInfo.corp = "河北华拓建设工程技术咨询事务所有限公司"
			}
			

			VueEvent.on("userLogin", (userObj) => {
				//alert("user==="+JSON.stringify(userObj))
				console.log("login....");
				localService.utils.setUserInfo(userObj);
				_this.hasLogin = true;
				_this.userInfo = userObj;
			})
			$(function(){
				
			});
		},
		computed: {
		},
		methods: {
			onSearchWithKey(){
				if(this.$router.currentRoute.path=="/"){
					this.onSearch({key:this.kwords})
				}else{
					
					this.$router.push({
						path: "/",
						query: {userKey:this.kwords}
					}, ()=>{}, ()=>{})
				}
			},
			onSearch(args) {
				let params={}
				if(args && !args.isTrusted){
					for(var k in args){
						params[k]=args[k]
					}
				}else{
					params.key=this.kwords
				}
				
				if(!params.key){
					if(this.kwords!="")
						params.key=this.kwords
				}
				if(!params.type){
					params.type=this.category
				}

				VueEvent.emit("filterTemplates",params);
				
				
			},
			onSearch_old() {
				if(this.$router.currentRoute.path.indexOf("list")>-1){
					VueEvent.emit("filterTemplates",{kwords:this.kwords,type:this.type_filter});
				}else{
					var query = {};
					if(this.kwords.trim()!=""){
						if(this.type_filter!=""){
							query = {"txtKey": localService.utils.encodeCode(this.kwords.trim()),"type": localService.utils.encodeCode(this.type_filter)}
						}else{
							query = {"txtKey": localService.utils.encodeCode(this.kwords.trim())}
						}
					}
					this.$router.push({
						path: "/list",
						query: query
					}, ()=>{}, ()=>{})
				}
			},
			linkTo(path){
				if(path == "/"){
					this.$router.push({
						path: path,
						query: {}
					}, ()=>{}, ()=>{})
				}else{
					if(localService.utils.isUserLogin()){
						this.$router.push({
							path: path,
							query: {}
						}, ()=>{}, ()=>{})
					}else{
						VueEvent.emit("execLogin");
					}
				}
			},
			login() {
				VueEvent.emit("execLogin");
			},
			logout() {
				var _this = this;
				console.log("logout....")
				localService.utils.setUserInfo();
					_this.hasLogin = false;
					_this.$router.push({
						path: "/",
						query: {}
					}, ()=>{}, ()=>{})
				/*
				localService.http.request({
					url: "/logout",
					params: {
					}
				}).then(res => {
					localService.utils.setUserInfo();
					_this.hasLogin = false;
					_this.$router.push({
						path: "/",
						query: {}
					}, ()=>{}, ()=>{})
				})
				*/
			},
			categoryClick(item){
				this.kwords=""
				this.selectedType = item;
				if(this.$router.currentRoute.path=="/"){
					this.onSearch({type:this.selectedType.value})
				}else{
					this.$router.push({
						path: "/",
						query: {certType:item}
					}, ()=>{}, ()=>{})
				}

			},
			seriesClick(item,index){
				this.category = item;
				let left = (this.$refs.seriesItem[index].offsetLeft - this.$refs.series.offsetLeft).toFixed(2);
				let width = this.$refs.seriesItem[index].offsetWidth.toFixed(2);
				this.left = left;
				this.width = width;
				this.onSearch({type:this.category})
			},
			keyClick(key){
				/*
				var query = {"txtKey": localService.utils.encodeCode(key.trim())};
				this.$router.push({
					path: "/list",
					query: query
				}, ()=>{}, ()=>{})
				*/
				this.onSearch({key:key})
			},
			test() {
				localService.http.request({
					url: "/ds/user.test.test",
					params: {}
				}).then(res => {
					//sessionStorage.setItem("moduleList",JSON.stringify(res.data))
					alert("data==" + JSON.stringify(res.data))

				});
			},
			loadHyfl() {
				//this.$store.commit("setUserInfo",{name:"test1",age:10})
				let inst = this
				localService.http.request({
					url: "/common/js/cert_hyfl.json",
					params: {}
				}).then(res => {
					inst.hyfl=[{id:"0000",name:"所有行业"}]
					for(var i=0;i<res.data.children.length;i++){
						inst.hyfl.push(res.data.children[i])
					}

					
					if(this.$route.query && this.$route.query.certType){ //从详情页选择认证类别跳转
						this.categoryClick(this.$route.query.certType)	
					}else if(this.$route.query && this.$route.query.userKey){ //从详情页选择认证类别跳转
						this.kwords=this.$route.query.userKey
						this.onSearch()
					}else{
						inst.onSearch({type:inst.category})
					}
					
				});
			},
			showSubItem(item) {
				this.selectedHyItem = item;
				this.cateId1 = item.id;
				this.cateId2 = 0;
			},
			show(event) {
				let targetNode = event.target.nodeName == "IMG" ? event.target.parentNode : event.target
				//console.log("父组件传过来的event", event);
				let x = event.x; //鼠标点击的x坐标
				let y = event.y; //鼠标点击的y坐标
				console.log(x,y)
				let menuWidth = 400; //菜单宽度，这个与.popup-menu样式对应的宽度一致
				let menuHeight = 350; //this.menuData.length * 30 + 20; //菜单高度，根据菜单项自动计算的高度

				//+parseFloat(targetNode.clientWidth/3)
				this.clientX = (parseFloat(x) - 60) + "px"; //(targetNode.offsetLeft + 5) + "px" 
				this.clientY = (targetNode.offsetTop + targetNode.clientHeight + 7) + "px"; // (parseFloat(y) + 10) + "px";

				let windowWidth = document.documentElement.clientWidth; // 实时屏幕宽度
				let windowHeight = document.documentElement.clientHeight; // 实时屏幕高度

				if (parseFloat(x) + menuWidth > windowWidth) {
					this.clientX = (parseFloat(windowWidth) - menuWidth - 50) + "px";
				}
				if (parseFloat(y) + menuHeight > windowHeight) {
					this.clientY = (parseFloat(windowHeight) - menuHeight - 50) + "px";
				}
				this.showCategory = true;
				event.stopPropagation(); //阻止事件冒泡
				document.addEventListener("click", this.closeMenu, false); // 添加关闭事件
				document.addEventListener("mousemove", this.closeMenu, false); //销毁关闭事件
				window.addEventListener("scroll", this.closeMenu, false)
			},
			isInPopup(node) {
				let tNode = node
				while (tNode.nodeName != "BODY") {
					tNode = tNode.parentNode
					if (!tNode)
						return false
					if (tNode.className && (tNode.className.indexOf("menu-dropdown") >= 0 || tNode.className.indexOf(
							"search-area") >= 0))
						return true
					//onsole.log("\t--"+tNode.nodeName+"=="+tNode.className)
				}
				return false
			},
			closeMenu() {
				let isInMenu = this.isInPopup(event.target)
				if (isInMenu) {
					//console.log("菜单event:");
					return
				}

				if (event.type == "mousemove") {
					let nodes = document.getElementsByClassName("menu-dropdown")
					if (nodes.length > 0) {
						let menuNode = nodes[0]
						//console.log("menuNode::",menuNode)
						//console.log("x="+menuNode.offsetLeft+",y="+menuNode.offsetTop+",w="+menuNode.clientWidth+",h="+menuNode.clientHeight+",mx="+event.clientX+",my="+event.clientY)
						let x = event.clientX
						let y = event.clientY
						if (x >= menuNode.offsetLeft - 1 && x <= menuNode.offsetLeft + menuNode.clientWidth + 1 &&
							y >= menuNode.offsetTop - 5 && y <= menuNode.offsetTop + menuNode.clientHeight) {
							//console.log("in ret....")
							return
						}
					}
				}

				console.log("销毁监听事件。type=" + event.type);
				document.removeEventListener("click", this.closeMenu, false); //销毁关闭事件
				document.removeEventListener("mousemove", this.closeMenu, false); //销毁关闭事件
				window.removeEventListener("scroll", this.closeMenu, false)
				this.showCategory = false;
			},
			industryItemClick(item) {
				this.selectedHyItem = item;
				this.cateId1 = item.id;
				if(item.name.length>=12){
					this.cateText = item.name.substr(0,10)+"...";
				}else{
					this.cateText = item.name;
				}
				
				let id = event.target.getAttribute("data-id"); //获取菜单项id
				//this.$emit('menuClick', id);//传参调用父组件事件，让父组件知道是点击到哪个菜单项
				//event.stopPropagation();//阻止事件冒泡
				console.log("销毁监听事件。");
				document.removeEventListener("click", this.closeMenu, false); //销毁关闭事件
				document.removeEventListener("mousemove", this.closeMenu, false); //销毁关闭事件
				window.removeEventListener("scroll", this.closeMenu, false)
				this.showCategory = false;
				this.onSearch({industry:item.name});
			}
		}
	}
</script>
<template>
	<div>
		<div :class='"top "+ theme'>
			<div class="wrap">
				<div class="left" @click="linkTo('/')">
					<img src="../assets/images/title_new.png" />
				</div>
				<div class="center" >
					<ul v-if="showMenu&&hasLogin">
						<li :class="menuId==0?'on':''"><a @click="linkTo('/')">首页</a></li>
						<li :class="menuId==1?'on':''"><a @click="linkTo('/project')">我的项目</a></li>
						<!--
						<li :class="menuId==2?'on':''"><a @click="linkTo('/template')">我的模板</a></li>
						<li :class="menuId==3?'on':''"><a @click="linkTo('/account')">我的账户</a></li>
						-->
						<li :class="menuId==4?'on':''"><a @click="linkTo('/favs')">我的收藏</a></li>
					</ul>
				</div>
				<div class="cate-list" v-if="showCatList">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								plain
								v-bind="attrs"
								v-on="on"
								style="border-radius: 0px;border: solid 1px gray;font-weight: bold;width: 100% !important;padding:0px;"
								>
								<div style="display: flex;flex-direction: row;width:100%;">
									<div style="flex: 0 1 90%;text-align: left;padding-left: 10px;padding-top: 3px;">{{selectedType.name}}</div>
									<div style="flex: 0 1 10%;">
										<v-icon color="darken-2">
											mdi-chevron-down
										</v-icon>
									</div>
								</div>
							</v-btn>
							
						</template>
						<v-list width="240">
							<v-list-item link
								style="padding: 0px 10px !important;"
								v-for="(item, index) in certTypes"
								:key="index"
							>
							<v-list-item-content @click="categoryClick(item)">{{ item.name }}</v-list-item-content>
							</v-list-item>
						</v-list>
						</v-menu>
				</div>
	
				<div class="search" v-if="showSearch">
					<div class="cont">
						<input v-model="kwords" @change="onSearch" :placeholder="this.searchPlaceHolder"/>
						<v-icon @click="onSearchWithKey">mdi-magnify</v-icon>
					</div>
				</div>
				
				<div class="right">
					<span v-if="!hasLogin" @click="login">登录</span>
					<div class="user" v-else >
						<div class="user-text">
							<img :src="userInfo.headImgUrl" :alt="userInfo.nickname">
							<div class="user-name">
								<h2>{{userInfo.nickname}}</h2>
								<p v-if="userInfo.corp!=''">{{userInfo.corp}}</p>
							</div>
						</div>
						<div class="user-menu">
							<v-btn plain @click="logout"><v-icon>mdi-logout</v-icon>退出登录</v-btn>
							<!--
							<v-icon>mdi-menu</mdi-label-off></v-icon>
							<div class="menuCont">
								<p @click="linkTo('/project')"><v-icon>mdi-account</v-icon>用户中心</p>
								<p @click="logout"><v-icon>mdi-logout</v-icon>退出登录</p>
							</div>
							-->
						</div>
					</div>
				</div>
			</div>
		</div>
		<Transition>
			<div v-if="showCategory" class="menu-dropdown position-fixed popup-menu flex flex-col" :style="{top:clientY, left:clientX}">
				<div class="menu">
					<ul>
						<li v-for="(item, index) in hyfl" :class="cateId1==item.id?'on':''">
							<a href="#" @click="industryItemClick(item)" :title="item.name">{{item.name}}</a>
						</li>
					</ul>
				</div>
			</div>
			<!-- <div v-if="showCategory" class="menu-dropdown position-fixed popup-menu flex flex-col" :style="{top:clientY, left:clientX}">
				<div class="menuWrap">
					<div class="menu1">
						<ul>
							<li>行业大类</li>
							<li v-for="(item, index) in hyfl" :class="cateId1==item.id?'on':''">
								<a href="#" @click="showSubItem(item)" :title="item.name">{{item.name}}</a><v-icon>mdi-chevron-right</v-icon>
							</li>
						</ul>
					</div>
					<div class="menu2">
						<ul>
							<li>细分类别</li>
							<li v-for="(item, index) in selectedHyItem.children" :class="cateId2==item.id?'on':''">
								<a href="#" @click="itemClick(item)" :title="item.name">{{item.name}}</a><v-icon>mdi-chevron-right</v-icon>
							</li>
						</ul>
					</div>
				</div>
			</div> -->
		</Transition>
		<!-- 
		<div class="container-main" v-if="!showSearch">
			<div class="series" ref="series">
				<span :class="item==category?'on':''" @click="seriesClick(item,index)" v-for="(item,index) in series" ref="seriesItem">{{item}}</span>
			</div>
			<div class="slide"><span :style='"left:"+left+"px; width:"+width+"px;"'></span></div>
			<div class="search-area">
				
				<button class="btn btn-default btn-category" @click="show" :title="cateText">
					{{cateText}}
					<img v-if="showCategory" src="../assets/images/icon_dropdown_up.svg">
					<img v-else src="../assets/images/icon_dropdown.svg">
				</button>
				
				<input type="text" class="search-box" :placeholder="this.searchPlaceHolder" v-model="kwords" @change="onSearch" />
				<v-btn @click="onSearch" class="btn-search">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</div>
			<div class="keywords">
				<template v-for="item in keywords">
					<span @click="keyClick(item)">{{item}}</span>
				</template>
			</div>
		</div>
		-->
	</div>
</template>

<style scoped>
	.top {
		
		width: 100vw;
		height: 70px;
		position: fixed;
		top: 0;
		z-index: 10;
		/*box-shadow: 0 0 8px rgba(0, 0, 0, .2);*/
		box-shadow: 4px 4px 2px #88888822;
		background-color: #fff;
	}
	
	/*
	.top.light {
		background-color: #fff;
		color: #fff;
		border-bottom: 1px solid rgba(255, 255, 255, .15);
		position: absolute;
		background-size: cover;
		background-position: top;
		
		
	}*/

	.top .wrap {
		width: 85vw;
		min-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
	.top .wrap .left {
		padding-top: 19px;
		cursor: pointer;
	}
	.top .wrap .left img {
		display: block;
	}
	.top .wrap .center{
		width: 40%;
		padding: 0px 15px;
	}
	.top .wrap .center ul {
		padding: 0;
		margin: 0;
		list-style: none;
		line-height: 70px;
	}
	.top .wrap .center ul li {
		display: inline-block;
		position: relative;
		margin-left: 15px;
		min-width: 100px;
		text-align: center;
	}
	.top .wrap .center ul li a {
		display: block;
		padding: 0 10px;
		color: inherit;
	}
	.top .wrap .center ul li a:hover {
		background: rgba(27, 102, 230, .1);
	}
	.top .wrap .center ul li.on {
		color: #1b66e6;
	}
	.top .wrap .center ul li.on::after {
		content: "";
		display: block;
		width: 32px;
		height: 2px;
		background: #1b66e6;
		position: absolute;
		bottom: 0px;
		left: 50%;
		margin-left: -16px;
	}

	.top .wrap .cate-list{
		height: 32px;
		width: 200px !important;
		line-height: 32px;
		margin: 18px 0;
		position: relative;
		margin-right: 10px;
		text-align: right;
	}

	
	.top .wrap .search{
		width: 200px;
		height: 36px;
		line-height: 36px;
		font-size: 13px;
		margin: 18px 0;
		border: 1px solid gray;
		border-radius: 0px;
		position: relative;
		color: gray;

	}
	.top .wrap .search .cont{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 100%;
		line-height: 100%;
		font-weight: bold;
	}
	.top .wrap .search .cont div{
		display: flex;
		position: relative;
	}
	.top .wrap .search .cont div::after{
		content: "";
		display: block;
	    width: 1px;
	    background: #ccc;
	    height: 16px;
		position: absolute;
		right: 0;
	    top: 7px;
	}
	.top .wrap .search .cont font{
		display: block;
		max-width: 100px;
		padding-left: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}
	.top .wrap .search .cont i{
	    line-height: 28px;
	    height: 30px;
	    font-size: 22px;
	    margin: 0 5px;
	}
	.top .wrap .search .cont input{
		flex-grow: 1;

		border: none;
		outline: none;
		padding: 0 10px;
	}
	.top .wrap .search .cont button{
		background: #fff;

		line-height: 30px;
		height: 30px;
		cursor: pointer;
		margin-right: 5px;
		box-shadow: none;
	}
	.top .wrap .right{
		width: 20%;
		position: relative;
	}
	.top .wrap .right span {
		display: block;
		text-align: center;
		background-color: #f8f8f8;
		border: 1px solid #dcdcdc !important;
		border-radius: 1px;
		font-size: 14px;
		width: 30%;
		line-height: 28px;
		margin-top: 19px;
		cursor: pointer;
		position: absolute;
		right: 35%;
	}
	.top .wrap .right span:hover {

		background-color: #efefef !important;
	}
	
	.top .wrap .right .user{
		display: flex;
		flex-wrap: nowrap;
		line-height: 70px;
		justify-content: center;
	}
	.top .wrap .right .user .user-text{
		display: flex;
		padding: 0 10px;
		cursor: pointer;
	}
	.top .wrap .right .user .user-text:hover{
		background: rgba(27, 102, 230, .1);
	}
	.top.light .wrap .right .user .user-text:hover{
		background: rgba(255, 255, 255, .1);
	}
	.top .wrap .right .user img {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background: #ccc;
		margin-top: 15px;
	}
	.top .wrap .right .user .user-name{
		margin: 0 0 0 10px;
		height: 70px;
		display: flex;
		flex-direction: column;
		justify-content: CENTER;
	}
	.top .wrap .right .user .user-name h2{
		font-size: 13px;
		line-height: 20px;
		font-weight: normal;
	}
	.top .wrap .right .user .user-name p{
		font-size: 12px;
		line-height: 20px;
		margin: 0;
	}
	.top .wrap .right .user .user-menu{
		cursor: pointer;
		padding:  0 10px;
		position: relative;
		
	}
	.top .wrap .right .user .user-menu:hover{
		background: rgba(27, 102, 230, .1);
	}
	.top.light .wrap .right .user .user-menu:hover{
		background: rgba(255, 255, 255, .1);
	}
	.top .wrap .right .user .user-menu i{
		vertical-align: sub;
		color: #666;
	}
	.top.light .wrap .right .user .user-menu i{
		color: #fff;
	}
	.top .wrap .right .user .user-menu .menuCont{    
		position: absolute;
		height: 0;
		transition: all .2s;
		overflow: hidden;
		right: 0;
		line-height: 28px;
		width: 120px;
		text-align: center;
		font-size: 12px;
		background: #fff;
		color: #333;
		border-radius: 1px;
		
	}
	.top .wrap .right .user .user-menu .menuCont p{
		margin: 0;
		padding: 6px 10px;
		text-align: left;
	}
	.top .wrap .right .user .user-menu .menuCont p:hover{
		background: rgba(27, 102, 230, .1);
	}
	.top .wrap .right .user .user-menu .menuCont p i{
		color: #666;
		font-size: 20px;
		vertical-align: sub;
		margin-right: 5px;
	}
	.top .wrap .right .user .user-menu .menuCont p i.mdi-logout{
		font-size: 18px;
	}
	.top .wrap .right .user .user-menu:hover .menuCont{
		height: auto;
		padding: 10px 0;
		box-shadow: 0 2px 10px rgba(0,0,0,.2);
	}
	
	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.5s ease;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}

	.container-main {
		width: 100vw;
		height: 250px;
		display: flex;
		place-items: center;
		justify-content: center;
		flex-direction: column;
		/*background-image: url(/src/assets/home-bg.jpg);*/
		background-size: cover;
		background-position: top;
		padding-top: 70px;
		/* margin-top: -70px; */
	}
	.container-main .series{
		width: 770px;
		color: #333;
		font-size: 15px;
		text-align: center;
	}
	.container-main .series span{
		display: inline-block;
		margin: 0 16px;
		padding: 0 0 10px 0;
		cursor: pointer;
	}
	.container-main .series span:hover{
		color: #FFC107;
	}
	.container-main .series span.on{
		/* border-bottom: 2px solid #FFC107; */
		color: #FFC107;
	}
	.container-main .slide{
		width: 770px;
		height: 2px;
		position: relative;
	}
	.container-main .slide span{
		position: absolute;
		display: block;
		height: 2px;
		background: #FFC107;
		transition: all .2s;
	}
	.container-main .keywords {
		color: #333;
		font-size: 14px;
		margin-top: 20px;
		text-align: center;
	}

	.container-main .keywords span {
		margin: 0 10px;
		cursor: pointer;
		padding: 3px 12px;
		border-radius: 2px;
		background: rgba(255,255,255,.2);
	}
	.container-main .keywords span:hover{
		/* text-decoration: underline; */
		background: rgba(255,255,255,.4);
	}

	.search-area {
		width: 770px;
		height: 50px;
		display: flex;
		background-color: #fff;
		border: solid 1px #efefef;
		padding: 5px 5px 5px 20px;
		border-radius: 5px;
	}
	.search-area .btn-search{
		background: #1b66e6;
		box-shadow: none;
		height: 38px;
	}
	.search-area .btn-search i{
		color: #fff;
	}
	
	.menu-dropdown {
		position: absolute;
		left: 40%;
		top: 140px;
		z-index: 999;
		display: flex;
		font-size: 14px;

		::-webkit-scrollbar {
		  /*滚动条整体样式*/
		  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
		  height: 1px;
		}
		::-webkit-scrollbar-thumb {
		  /*滚动条里面小方块*/
		  border-radius: 10px;
		  height: 20px;
		  background: #a5acb2;
		}
		::-webkit-scrollbar-track {
		  /*滚动条里面轨道*/
		  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
		  border-radius: 10px;
		  background: #ffffff;
		}
	}
	.menu-dropdown .menu{
		width: 630px;
		background: #fff;
		height: 100%;
		margin-right: 1px;
		box-shadow: 0 0 5px rgba(0,0,0,.2);
		padding: 5px 0;
		padding-bottom: 15px;
	}
	.menu-dropdown .menu ul{
		list-style: none;
		padding: 0 15px 0 5px;
	}
	.menu-dropdown .menu ul li{
		float: left;
		text-align: center;
		padding: 0 10px;
		background: #f6f6f6;
		margin-left: 10px;
		margin-top: 10px;
		line-height: 26px;
		border-radius: 2px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #333;
	}
	.menu-dropdown .menu ul li a{
		color: inherit;
	}
	.menu-dropdown .menu ul li:hover{
		background: rgba(27, 102, 230, .1);
		color: #1b66e6;
	}
	.menu-dropdown .menu ul li.on{
		background: #1b66e6;
		color: #fff;
	}
	
	.menu-dropdown .menuWrap{
		width: 200px;
		height: 354px;
		background: #fff;
		overflow-y: scroll;
		margin-right: 1px;
		box-shadow: 0 0 5px rgba(0,0,0,.2);
		padding: 5px 0;
		line-height: 32px;
	}
	.menu-dropdown .menuWrap ul {
		list-style: none;
		padding: 0;
	}
	.menu-dropdown .menuWrap ul li{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 5px 0 20px;
		color: #333;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.menu-dropdown .menuWrap ul li.on{
		background: rgba(27, 102, 230, .1);
		color: #1b66e6;
	}
	.menu-dropdown .menuWrap ul li:not(:first-child):hover{
		background: rgba(27, 102, 230, .1);
		color: #1b66e6;
	}
	.menu-dropdown .menuWrap ul li:first-child{
		font-weight: bold;
		color: #1b66e6;
	}
	.menu-dropdown .menuWrap ul li i{
		font-size: 20px;
		color: #999;
	}
	.menu-dropdown .menuWrap ul li a {
		color: initial;
		text-decoration: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>